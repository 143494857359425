


































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { OrganizationReport } from "@/models/organization";
import { ReportAsReqBody } from "@/models/app-api";
import XDialog from "../SimpleDialog.vue";
import XDropdownWithDatePicker from "../hoc/DropdownWithDatePicker.vue";
import XDropdownWithDatePickerWithoutRange from "../hoc/DropdownWithDatePickerWithoutRange.vue";
import { documentGetterForm } from "@/assets/scripts/form-fields/main";
import AppApiMixin from "../mixins/AppApi.vue";
import DocGetterMixin from "../mixins/DocGetter.vue";
import {da} from "date-fns/locale";

const { values, attrs, errors, validations } = documentGetterForm;

@Component({
  components: {
    XDialog,
    XDropdownWithDatePicker,
    XDropdownWithDatePickerWithoutRange,
  },
  mixins: [validationMixin],
  computed: {
    ...errors,
    ...mapGetters("user", { month: "month" }),
    ...mapGetters({ contractId: "contract/id", userEmail: "user/email" }),
  },
  validations,
})
class DocGetterDialog extends Mixins(AppApiMixin, DocGetterMixin, XDialog) {
  [x: string]: any;

  @Prop({ required: true }) readonly showDialog!: boolean;
  @Prop({ required: true }) readonly report!: OrganizationReport | null;

  @Prop({ default: false }) readonly isSendMode!: boolean;

  date = "";
  dates: string[] = [];

  values = { email: values.email };
  fieldAttrs = attrs.email;

  useAgreeButtonLoading = false;
  submitErrorText = "";

  public get itemTexts() {
    return this.isSendMode
      ? { dialogTitle: "Отправить отчет на email", agreeButton: "Отправить" }
      : { dialogTitle: "Скачать отчет", agreeButton: "Скачать" };
  }

  public get useDateRange(): boolean {
    return (
      !!this.report &&
      this.report["вариантпостроения"].toLowerCase().startsWith("период")
    );
  }

  get maxDate(): Date {
    const [day, month, year] = this.month.split('.');
    let date = new Date(+year, +month - 1, +day);
    return date;
  }

  private subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
  }
  @Watch("showDialog")
  public showDialogChanged() {
    if (this.showDialog) {
      this.values.email = this.userEmail;
      return;
    }

    this.values.email = "";
    this.submitErrorText = "";

    this.$v.$reset();
  }

  public onAgree(evt: { name: string; payload: null }) {
    this.submitErrorText = "";
    this.useAgreeButtonLoading = true;

    const {
      contractId,
      isSendMode,
      propagateCustomEvt,
      report,
      useDateRange,
      values,
    } = this;

    const reqBody: ReportAsReqBody = {
      contractId,
      report: report ? report["отчет"] : "",
    };

    if (isSendMode) {
      reqBody.emailSend = true;
      reqBody.email = values.email;
    } else {
      reqBody.download = true;
    }

    if (useDateRange) {
      const [from, to] = this.dates;

      reqBody.from = from;
      reqBody.to = to;
    } else {
      reqBody.month = this.date;
    }

    this.getReportByContract(reqBody)
      .then((response) => {
        if (!isSendMode) {
          this.downloadDocument(response);
          return propagateCustomEvt(evt.name);
        }
        propagateCustomEvt(evt.name, values.email);
      })
      .catch((_) => (this.submitErrorText = "Непредвиденная ошибка"))
      .finally(() => (this.useAgreeButtonLoading = false));
  }
}

export default DocGetterDialog;
